import axios from 'axios';
import * as moment from "moment";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { default as VUploadsForm } from '../components/UploadsForm/UploadsForm';
import {
// properties
    APP_URL
// reducer names
   ,MODAL
   ,UPLOADS
} from '../redux/constants';
import {
    docChange
   ,groupChange
   ,load
} from '../redux/actions/admin';
import {
    get_common
   ,setKeyPropValue
   ,setPropValue
   ,setMessage
   ,setProps
} from '../redux/actions/utils';
import {
    changeUploadStatus
   ,loadMassDocGenerationFile
   ,loadMassDocGenerationTemplate
   ,startMassDocGeneration
} from '../redux/actions/uploads';
import {requestFile} from "../redux/actions/doc";
import {
    caption
} from '../redux/actions/lang';


let needUpdate = true; // если ушли со страницы Масс-загрузки, обновление данных не нужно

class UploadsForm extends Component {

    // вызывается при смене Шаблона и Статуса,
    // а при переходе на страницу - не вызывается
    componentDidUpdate( prevProps ) {
        // загрузить шаблон из базы, если его нет
        load( this.props, prevProps );
        if( this.props.doc.docId !== prevProps.doc.docId ) {
            setTimeout( () => this.props.onUpdateUploads(), 100 );
        }
    }

    componentDidMount() {
        //if( needUpdate )
        setTimeout( () => this.props.onUpdateUploads(), 100 );
        needUpdate = true;
    }

    componentWillUnmount() {
        //if( this.props.modal.msg )
        needUpdate = false;
    }

    render() {
        return (
             <VUploadsForm
                 { ...this.props }
             />
        )
    }
}


const mapStateToProps = ( { doc, uploads, role, spin, user, modal } ) => {
    return {
        doc
       ,uploads
       ,role
       ,spin
       ,user
       ,modal
    };
};


// обновление таблицы результатов
export function getUploads( dispatch ) {
    const props = dispatch( ( dispatch, getState ) => getState() );
    const id = props.doc.docId;
    const uploads = props.uploads;
    const dateFrom = uploads.dateFrom;
    const dateTo = uploads.dateTo;
    const limit = uploads.limit === '' ? '' : Number.parseInt( uploads.limit, 10 );
    const tzOffset = uploads.tzOffset;
    const dateProcFrom = uploads.dateProcFrom;
    const dateProcTo = uploads.dateProcTo;
    const status = uploads.status;
    const fileName = uploads.fileName;
    const login = uploads.login;

    if( !id ) return;
    if( limit !== '' && ( limit <= 0 || !Number.isInteger( limit ) ) ) return;
    if( !moment( dateFrom, "DD.MM.YYYY" ).isValid() ) return;
    if( !moment( dateTo,   "DD.MM.YYYY" ).isValid() ) return;
    if( dateProcFrom !== '' && !moment( dateProcFrom, "DD.MM.YYYY" ).isValid() ) return;
    if( dateProcTo   !== '' && !moment( dateProcTo,   "DD.MM.YYYY" ).isValid() ) return;

    if( cancelRequest ) {
        cancelRequest.cancel();
    }
    cancelRequest = axios.CancelToken.source();

    get_common(
        APP_URL + '/api/uploads'
       ,dispatch
       ,caption.uploadsGetError
       ,data => {
            dispatch( setKeyPropValue( UPLOADS, id, 'uploads', data ) );
            cycle( dispatch );
        }
       ,f=>f
       ,() => cycle( dispatch ) // ошибка не выводится, просто перезапрос
       ,{
            cancelToken: cancelRequest.token
           ,params: { id, dateFrom, dateTo, limit, tzOffset, dateProcFrom, dateProcTo, status, fileName, login }
        }
       ,false
    );
}


function cycle( dispatch ) {

    // запрос каждые 5 секунд
    if( isTimer || !needUpdate ) return;
    setTimeout( () => { isTimer = false; getUploads( dispatch ); }, 5000 );
    isTimer = true;
}


// Определяем есть ли у данного шаблона компонент "MassDocGeneration"
const  massDocGenerationComponentExists = ( dispatch, docId ) => {
    const props = dispatch((dispatch, getState) => getState());
    const massDocGenerationComponents = props.doc.docTemplates[docId].filter(i => i.type === "MassDocGeneration");
    return massDocGenerationComponents.length !== 0;
}

let cancelRequest; // защита от наплыва повторных HTTP-запросов
let isTimer = false; // защита от повторной установки таймера
const mapDispatchToProps = dispatch => ({
    dispatch
   ,onDocChange: ( e ) => docChange( dispatch, e )
   ,onGroupChange: ( e, oldGroupId ) => groupChange( dispatch, e, oldGroupId )
   ,onChange: ( e ) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked :
                      target.type === 'file' && target.files[0] ? target.files[0].name :
                      target.value;
        dispatch( setPropValue( UPLOADS, name, value ) );
    }
   ,onNameValueChange: ( name, value ) => {
        dispatch( setPropValue( UPLOADS, name, value ) );
    }
    // форму убрать нельзя, Material UI компоненты привязываются к форме
   ,onFormSubmit: ( e ) => {
        e.preventDefault();
    }
    // выгрузить файл с результатами масс-загрузки
   ,onDownload: ( uploadId, fileName, format ) => {
        if( !Number.isInteger( uploadId ) ) return;
        
        const formData = new FormData();
        formData.append( 'uploadId', uploadId );
        // formData.append( 'xxcdoc_lang', gLang ); // уже передаётся в requestFile()
        // если передать два раза - Spring проигнорирует параметр!

        requestFile( APP_URL + "/api/uploads/downloadDocGenerationFile", fileName, format, dispatch, formData );

    }
   ,onUpdateUploads: () => getUploads( dispatch )
   ,onLoadMassDocGenerationTemplate: (docId) => {
        if ( !docId ) return;
        massDocGenerationComponentExists(dispatch, docId)?
            loadMassDocGenerationTemplate(dispatch, docId)
            :
            dispatch( setMessage( caption.uploadsNoComponent ) );
    }
    // загрузить файл пользователя для масс-загрузки
   ,onLoad: ( e, docId ) => {
        if ( !docId ) return;
        const file = e.target.files[0];
        e.target.value = ''; // reset, to enable same file re-opening
        if( file )
        massDocGenerationComponentExists( dispatch, docId ) ?
            dispatch( ( dispatch, getState ) => { loadMassDocGenerationFile( dispatch, getState, file ) } )
            :
            dispatch( setMessage( caption.uploadsNoComponent + caption.uploadsNotAllowed ) );

    }
    // остановить загрузку файла с данными, процесс масс-загрузки или мерж результатов
   ,onStop: ( docId, uploadId ) => {
    
        dispatch( setProps( MODAL, {
            msg: caption.cancelUpload + "?"
           ,onOk: () => {
                changeUploadStatus( dispatch, docId, uploadId, "CANCELED" );
                setTimeout( () => getUploads( dispatch ), 500 );
            }
        } ) );
        
    }
    // не хотим запускать загруженный файл масс-загрузки
   ,onCancel: ( docId, uploadId ) => {
        changeUploadStatus( dispatch, docId, uploadId, "CANCELED" );
        setTimeout( () => getUploads( dispatch ), 500 );
    }
   ,onStart: ( docId, uploadId ) => {
        startMassDocGeneration( dispatch, uploadId, docId );
        setTimeout( () => getUploads( dispatch ), 500 );
    }
});

export default connect( mapStateToProps, mapDispatchToProps )( UploadsForm );
