import React from 'react';
import Link from '@material-ui/core/Link';
import HiddenInput from "../../components/DocParts/HiddenInput";
import {
   // actions
    ADD_FIELD
   ,COPY_FIELD
   ,INSERT_FIELDS
   ,DELETE_FIELD
   ,SET_CHANGE_FLAG
   ,SET_TEMPLATE
   ,SET_ITEM
   ,SET_STATE
   // properties
   ,APP_URL
   // reducer names
   ,CDOC_PROP
   ,DOC
   ,FORM_TEMPLATE
   ,USER
   ,SESSION
} from '../constants';
import {
    copyValue
   ,get_common
   ,post_common
   ,setPropValue
   ,setKeyPropValue
} from '../actions/utils';
import {
    setDocStatePropValue
   ,setTemplateFormat
} from '../actions/doc';
import {
    storeLastDocId
   ,getLastDocId
   //,getLastDocState
} from '../actions/localStorage';
import {
    getHrOnePersonDB
} from '../actions/hrPersons';
import {
    errorSnackbar
   ,errorSnackbarLong
   ,successSnackbar
   ,successSnackbarLong
} from '../actions/snackbar';
import {
    loadRoleListDB
} from './role';
import {
    caption
   ,gLang
   ,isLangEn
} from './lang';


export function urlCopy() {
    copyValue( window.location.href );
    successSnackbar( caption.urlCopy ); // Ссылка на шаблон скопирована в буфер обмена
}

export const setTemplateChangeFlag = ( value ) => (
  {
    type: FORM_TEMPLATE + SET_CHANGE_FLAG,
    payload: {
        value
    }
  }
);

export const setTemplate = ( value ) => (
  {
    type: FORM_TEMPLATE + SET_TEMPLATE,
    payload: {
        value
    }
  }
);

export const setDocState = ( value ) => (
  {
    type: DOC + SET_STATE,
    payload: {
        value
    }
  }
);

export const setTemplateItem = ( key, value ) => (
  {
    type: FORM_TEMPLATE + SET_ITEM,
    payload: {
        key
       ,value
    }
  }
);

export const addField = ( value, key = null ) => (
  {
    type: FORM_TEMPLATE + ADD_FIELD,
    payload: {
        value,
        key
    }
  }
);

/*
// над полем добавляется копия, к Названию и Тегу добавляется "2"
export const copyField = ( key ) => (
  {
    type: FORM_TEMPLATE + COPY_FIELD,
    payload: {
        key
    }
  }
);
*/

// поле копируется в буфер обмена session.copy, можно вставить скопированные поля в другой шаблон
export const copyField = ( field ) => (
  {
    type: SESSION + COPY_FIELD,
    payload: {
        field
    }
  }
);

// вставить скопированные поля перед указанным полем шаблона
export const insertFields = ( key, fields ) => (
  {
    type: FORM_TEMPLATE + INSERT_FIELDS,
    payload: {
        key
       ,fields
    }
  }
);

export const deleteField = ( key ) => (
  {
    type: FORM_TEMPLATE + DELETE_FIELD,
    payload: {
        key
    }
  }
);

// Список Группа
export function getUserGroupSelect( props ) {
    // список групп загружается в App.js onGetDocNames(),
    // на внешней фроме Список Групп не выводим
    return props.user.login === null || props.user.isExternal || props.user.isIframe ||
        props.doc.docParts.Select.build(
            props
           ,props.doc
           ,{
                label: caption.group // Группа
               ,name: "groupId"
               ,listName: "groupNames"
               ,defaultValue: props.doc.groupId
               ,onChange: e => props.onGroupChange( e, props.doc.groupId )
            }
        );
}

// Список Шаблон
// TODO:
// для Суперпользователя загружается слишком большой список шаблонов,
// можно грузить шаблоны одной группы за раз
export function getUserDocSelect( props, showDocCode = true ) {
    // список шаблонов загружается в App.js onGetDocNames()
    const docNameObj = props.doc.docNames.find( i => i.id === props.doc.docId );
    const docName = docNameObj ? docNameObj.name : '';
    const docCode = docNameObj ? docNameObj.code : '';

    // на внешней фроме вместо Списка Шаблонов выводим название шаблона текстом и скрытое поле docId
    const DocSelect = props.user.login === null || props.user.isExternal || props.user.isIframe ?
        <>
        <HiddenInput name="docId" value = { props.doc.docId } />
        <div style={{ padding: '9px', fontSize: '22px' }}>
            { docName }
        </div>
        </>
    :
        props.doc.docParts.Select.build(
            props
           ,props.doc
           ,{
                label: caption.doc // Шаблон
               ,name: "docId"
               ,list: props.doc.docNames.filter( i => i.group_id === props.doc.groupId )
               ,defaultValue: props.doc.docId
               ,onChange: props.onDocChange
               ,helperText: docCode && showDocCode ? caption.docCode + ": " + docCode : '' // Код шаблона
            }
        );
    return [ DocSelect, docName, docCode ];
}

// Список Группа для админа
export function getAdminGroupSelect( props ) {
    // список групп загружается в App.js onGetDocNames()
    return props.doc.docParts.Select.build(
            props
           ,props.doc
           ,{
                label: caption.group // Группа
               ,name: "groupId"
                // список групп, в которых текущий пользователь является Админом группы или Админом шаблона
               ,list: props.doc.groupNames.filter( i => i.access_type === 'ADMIN' || props.doc.docAdminList.findIndex( tmp => tmp.group_id === i.id ) > -1 )
               ,defaultValue: props.doc.groupId
               ,onChange: e => props.onGroupChange( e, props.doc.groupId )
            }
    );
}

// Список Шаблон для админа
export function getAdminDocSelect( props, showCode = false ) {
    // список шаблонов загружается в App.js onGetDocNames()
    const docNameObj = props.doc.docNames.find( i => i.id === props.doc.docId );
    const docCode = docNameObj ? docNameObj.code : '';
    return props.doc.docParts.Select.build(
            props
           ,props.doc
           ,{
                label: caption.doc // Шаблон
               ,name: "docId"
               ,list: props.doc.docAdminList.filter( i => i.group_id === props.doc.groupId )
               ,defaultValue: props.doc.docId
               ,onChange: props.onDocChange
               ,helperText: showCode && docCode ? caption.docCode + ": " + docCode : '' // Код шаблона
            }
    );
}


export function setDocId( dispatch, value ) {
    dispatch( setPropValue( DOC, 'docId', value ) );
    storeLastDocId( value );
}


export function docChange( dispatch, e ) {
    
    const target = e.target;
    const value = target.value;
    setDocId( dispatch, value );
    
    // loadTemplateDB() вызывается в DocForm.js и AdminForm.js componentDidUpdate()
    
    // setDocIdToAddressBar() вызывается в NavBar.js shouldComponentUpdate()
    // там же закрываем Preview, иначе event не доходит до NavBar - может быть из-за смены layout?
}

export function groupChange( dispatch, e, oldGroupId ) {

    const target = e.target;
    const name = target.name;
    const value = target.value;
                  
    if( oldGroupId === value ) return;

    dispatch( setPropValue( DOC, name, value ) );
    dispatch( setPropValue( DOC, 'docId', '' ) );
}

export function saveTemplateDB( dispatch, docId, template ) {

    if( !Number.isInteger( docId ) || docId < 1 ) return;
    const formData = new FormData();
    formData.append('id', docId);

    const passData = new FormData();
    passData.append('id', docId);

    // Пароль для редактирования документа хранится отдельно,
    // в шаблоне документа pdfAdminPassword будет пустым.
    
    // если снята галка isProtected "Защита редактирования документа", pdfAdminPassword очищается
    
    const templateWithoutPassword = template.map( field => {
        let i = { ...field };
        if( i.type === 'SaveAndTicket' ) {
            passData.append( 'pdfAdminPassword', i.isProtected === true && i.pdfAdminPassword !== undefined ? i.pdfAdminPassword : '' );
            delete i.pdfAdminPassword;
        }
        return i;
    });
    
    formData.append('text', JSON.stringify( templateWithoutPassword ));

    const config = {headers: {'content-type': 'multipart/form-data'}};

    post_common(
        APP_URL + '/api/template/save'
       ,formData
       ,config
       ,dispatch
       ,caption.formSaveError + ': '
       ,data => {
            if( data === 'OK' ) {
                successSnackbar( caption.formSaved ); // Форма сохранена
                // снять флаг сохранения
                dispatch( setTemplateChangeFlag( false ) );
            } else {
                errorSnackbar( caption.formSaveError ); // Ошибка сохранения формы
            }
        }
    );

    // если есть компонент SaveAndTicket - сохраняем пароль для редактирования документа,
    // иначе - не сохраняем
    // TODO подумать над паролями в StartrekAttachDoc
    if( passData.get( 'pdfAdminPassword' ) !== null )
    post_common(
        APP_URL + '/api/template/save_pass'
       ,passData
       ,config
       ,dispatch
       ,caption.saveAdminPasswordError + ': '
       ,data => {
            if (data === 'OK') {
            } else {
                errorSnackbar( caption.saveAdminPasswordError );
            }
        }
    );
}


// получить из базы справочники, необходимые для работы некоторых компонентов,
// вызывается только для первой загрузки шаблона
function loadListsDB( dispatch, templateData ) {
 
    /* хотел сделать так для всех списков, но ещё
    нужно учесть случай, когда в шаблон добавляется компонента в Админке
 
    const propsDoc = dispatch( ( dispatch, getState ) => getState().doc );
    
    // загрузить yaOrgs - Список компаний группы Яндекс, если он пуст и есть компоненты YandexOrg... в шаблоне
    if( propsDoc.yaOrgs.length === 0 && templateData.findIndex( i => i.type.match( /^YandexOrg/ ) ) > -1 ) {
        loadYaOrgs( dispatch );
    }

    // загрузить startrekFieldsList, если он пуст и есть компонента StartrekIntegration в шаблоне
    if( propsDoc.startrekFieldsList.length === 0 && templateData.findIndex( i => i.type === "StartrekIntegration" ) > -1 ) {
        loadStartrekFields( dispatch );
    }*/

    // если в шаблоне есть компонента HrPersons, то загрузить данные HR по логину пользователя, и установить поля на форме,
    // только для компоненты с пустым suffix
    // TODO могут быть гонки с getLastDocState, но пока не замечено
    const idx = templateData.findIndex( i => i.type === "HrPersons" && ( i.suffix === undefined || i.suffix === null || i.suffix === '' ) );
    if( idx > -1 ) {
        // обычно справочник с пустым параметром login не вызываем, но если вызвали - вернуть данные по логину пользователя, логин известен в backend,
        // сделаю так, потому что логин на фронте ещё может быть не загружен
        //getHrPersonsDB( dispatch, '', true );
        const limitToBusinessGroups = templateData[ idx ].limitToBusinessGroups;
        getHrOnePersonDB( dispatch, '',  limitToBusinessGroups );
    }
}


export function loadTemplateDB( dispatch, docId, docAdminList, init = true ) {
    // init - если шаблон ещё не был загружен, проинициализировать [], загрузить, и не показывать баннер,
    // а если уже был - обновить из базы, и показать баннер 'Форма обновлена из базы'

    if( !Number.isInteger( docId ) || docId < 1 ) return;

    // init - если шаблон ещё не был загружен,
    // проинициализирую [],
    // потом подгружаю сохранённые значения из localStorage,
    // потом жду ответа от БД
    if( init ) {
        dispatch( setTemplate( [] ) );
        
        // перенёс под кнопку Redo "Заполнить как в прошлый раз" на форме
        // const lastDocState = getLastDocState( docId );
        // if( lastDocState ) dispatch( setDocState( lastDocState ) );
    }
    
    // передаём uuid пользователя внешних форм
    const props = dispatch( ( dispatch, getState ) => getState() );
    const extUserId = props.user ? props.user.extUserId : '';
    const isExternal = props.user ? props.user.isExternal : false;
    
    const formData = new FormData();
    formData.append('id', docId);
    formData.append('extUserId', extUserId);

    const config = {headers: {'content-type': 'multipart/form-data'}};

    let counter = 0;
    let password = '';
    let templateData = [];

    // загрузить шаблон
    dispatch( setPropValue( DOC, 'expiredUrl', false ) );
    post_common(
        APP_URL + '/api/template/load'
       ,formData
       ,config
       ,dispatch
       ,caption.templateLoadError + ': '
       ,data => {
            if( data === 'EXPIRED' ) {
                dispatch( setPropValue( DOC, 'expiredUrl', true ) );
            } else {
                templateData = data ? data : [];
                if( init ) loadListsDB( dispatch, templateData );
                nextSetTemplate();
            }
        }
    );
    
    // для внешней формы получить предзаполненное состояние
    let predefined = '';
    if( isExternal && extUserId )
        post_common(
            APP_URL + '/api/ext/predefined'
           ,formData
           ,config
           ,dispatch
           ,caption.predefinedLoadError + ': '
           ,data => {
                predefined = data;
                dispatch( setPropValue( DOC, 'predefined', predefined ) ); // запомнить начальное состояние для функции "Очистить форму"
                nextSetTemplate();
            }
        );
    else ++counter;

    
    // получить состояние формы, заполненной внешним пользователем
    let extdefined = '';
    if( !isExternal && extUserId )
        post_common(
            APP_URL + '/api/ext/extdefined'
           ,formData
           ,config
           ,dispatch
           ,caption.extdefinedLoadError + ': '
           ,data => { extdefined = data; nextSetTemplate(); }
        );
    else ++counter;
    

    // получить значения защищённых предзаполненных полей,
    // чтобы внешний пользователь не смог их подменить через extdefined
    let extprotected = '';
    if( !isExternal && extUserId )
        post_common(
            APP_URL + '/api/ext/extprotected'
           ,formData
           ,config
           ,dispatch
           ,caption.extprotectedLoadError + ': '
           ,data => { extprotected = data; nextSetTemplate(); }
        );
    else ++counter;
    
    
    // пароль для редактирования документа может увидеть Админ шаблона или Суперпользователь
    if( docAdminList.findIndex( i => i.id === docId ) > -1 )
        // загрузить пароль
        post_common(
            APP_URL + '/api/template/load_pass'
            ,formData
            ,config
            ,dispatch
            ,caption.loadAdminPasswordError + ': '
            ,data => { password = data; nextSetTemplate(); }
        );
    else ++counter;

    // После получения ответа всех запросов
    const nextSetTemplate = () => {
        if( ++counter === 5 ) {
            
            templateData.forEach( i => {
                if( i.type === "SaveAndTicket" ) i.pdfAdminPassword = password;
            } );
            
            // сейчас не смотрим в настройки шаблона, язык хранится в localStorage
            /*// если есть компонента "Язык компонентов", получить язык
            const langComponent = Array.isArray( templateData ) ? templateData.find( i => i.type === "Lang" ) : undefined;
            const lang = langComponent ? langComponent.lang : undefined;
            dispatch( setPropValue( SESSION, 'lang', lang ) );*/

            // сначала state,
            // state заполним из predefined / extdefined,
            // а если их нет, поля будут заполнены defaultValue в коде компонентов
            if( predefined ) dispatch( setDocState( predefined ) );
            if( extdefined ) dispatch( setDocState( extdefined ) );
            if( extprotected ) extprotected.forEach( i => {
                dispatch( setDocStatePropValue( i.key, i.value ) );
            });

            // затем шаблон
            dispatch( setTemplate( templateData ? templateData : [] ) );
            
            if( !init ) successSnackbar( caption.formReloaded ); // Форма обновлена из базы
            // снять флаг сохранения
            dispatch( setTemplateChangeFlag( false ) );
            
        }
    }
    
}


// получить docId из адресной строки
export function getDocIdFromAddressBar() {
    let docId = '';
    const regexp = /\/(-?\d+)\/?$/;
    const result = regexp.exec( window.location.pathname );
    if( result && result.length ) docId = Number( result[ result.length - 1 ] );
    return docId;
}

export const getCdocProperties = ( dispatch ) => {
    get_common(
        APP_URL + '/api/properties'
       ,dispatch
       ,caption.loadAppPropsError + ': '
       ,data => {
            data.forEach( attr => dispatch( setPropValue( CDOC_PROP, attr.attribute, attr.value ) ) );
        }
    );
}

// вызывается в App.js
export const getDocNames = ( dispatch ) => {
    
    // Список Суперпользователей
    // для всех, чтобы показать список уполномоченных, если нет доступа к шаблону
    get_common(
        APP_URL + '/api/role/superusers'
       ,dispatch
       ,caption.loadSuperusersError + ': '
       ,data => { dispatch( setPropValue( DOC, 'superuserList', data ) ); }
    );
    
    // сначала заполним таблицу XXYA.XXHR_CDOC_USER_DEPARTMENTS
    // из Staff списком подразделений по текущему логину
    
    // TODO можно перенести эту логику в backend -
    // прочесть подразделения,
    // потом одновременно запросить списки групп и шаблонов,
    // вернуть JSON с дувмя списками на frontend
    
    get_common(
        APP_URL + '/api/staff/person_groups'
       ,dispatch
       ,caption.loadDepartmentsError + ': '
       ,data => { nextStep(); }
    );
    
    // для внешнией формы - получить uuid из адресной строки
    const extUserId = dispatch( ( dispatch, getState ) => getState().user.extUserId );

    const nextStep = () => {
     
        // docID выбирается
        // 1) из адресной строки - если id задан в адресной строке, не подменяем, чтобы на запутать пользователя,
        //    если нет доступа, будет показано сообщение
        // 2) последний сохранённый в localStorage
        // 3) первый из списка шаблонов
        let docIdFromAddressBar = getDocIdFromAddressBar();
        let lastDocId = docIdFromAddressBar;
        if( !lastDocId ) lastDocId = getLastDocId();
        
        let docId = '';
        let docNames = [];
        get_common(
            APP_URL + '/api/template/names'
           ,dispatch
           ,caption.loadTepmlateListError + ': '
           ,data => {
                if( data === 'EXPIRED' ) {
                    dispatch( setPropValue( DOC, 'expiredUrl', true ) );
                } else {
                    if( !Array.isArray( data ) ) return;
                    data.sort( (a,b) => a.name.localeCompare(b.name) ); // сортируем, потому что результат backend отличается локально и в qloud
                    docNames = data;
                    dispatch( setPropValue( DOC, 'docNames', docNames ) );
                    
                    // список шаблонов с правами админа
                    const docAdminList = docNames.filter( i => i.access_type === 'ADMIN' );
                    dispatch( setPropValue( DOC, 'docAdminList', docAdminList ) );

                    if( data.length > 0 ) {
                     
                        // выбрать первый доступный шаблон
                        // если id задан в адресной строке, не подменяем, чтобы на запутать пользователя
                        let idx = data.findIndex( i => i.id === lastDocId );
                        if( idx === -1 && !docIdFromAddressBar ) idx = 0;
                        docId = ( idx === -1 ) ?  docIdFromAddressBar : data[ idx ].id;

                        setDocId( dispatch, docId );
                        
                        // loadTemplateDB() вызывается в DocForm.js и AdminForm.js componentDidUpdate()
                        // setDocIdToAddressBar() вызывается в NavBar.js shouldComponentUpdate()
                    }
                    
                    init();
                }
           }
          ,f=>f
          ,null
          ,{
               params: {
                   extUserId
               }
           }
        );
        
        let groupId = '';
        let groupNames = [];
        get_common(
            APP_URL + '/api/group/names'
           ,dispatch
           ,caption.loadGroupListError + ': '
           ,data => {
                if( !Array.isArray( data ) ) return;
                data.sort( (a,b) => a.name.localeCompare(b.name) ); // сортируем, потому что результат backend отличается локально и в qloud
                groupNames = data;
                dispatch( setPropValue( DOC, 'groupNames', groupNames ) );
                
                // список групп шаблонов с правами админа
                const groupAdminList = groupNames.filter( i => i.access_type === 'ADMIN' );
                dispatch( setPropValue( DOC, 'groupAdminList', groupAdminList ) );

                init();
           }
        );
        
        // groupId устанавливается после получения обоих списков, групп и шаблонов,
        // 1) groupId выбранного шаблона
        // 2) Общая группа по умолчанию
        let counter = 0;
        const init = () => {
            if( ++counter === 2 ) {
             
                initGroupId();
                initAccessType();

            }
        }

        const initGroupId = () => {
             
            if( groupNames.length > 0 ) {
                let idx = groupNames.findIndex( i => i.id === 1 );
                if( idx > -1 ) groupId = 1;
            }
            
            if( docId && docNames.length > 0 ) {
                let idx = docNames.findIndex( i => i.id === docId );
                if( idx > -1 ) groupId = docNames[ idx ].group_id;
            }
            
            dispatch( setPropValue( DOC, 'groupId', groupId ) );
        }
        
        // максимальный уровень прав
        // определяет видимость Админки
        const initAccessType = () => {
             
            let maxAccessType = 'USER';
            
            if( groupNames.length > 0 ) {
                let idx = groupNames.findIndex( i => i.access_type === 'ADMIN' );
                if( idx > -1 ) maxAccessType = 'ADMIN';
            }
            
            if( docNames.length > 0 ) {
                let idx = docNames.findIndex( i => i.access_type === 'ADMIN' );
                if( idx > -1 ) maxAccessType = 'ADMIN';
            }
            
            dispatch( setPropValue( USER, 'maxAccessType', maxAccessType ) );
        }
    }
}


// загрузить шаблон
export function uploadFile( dispatch, getState, file ) {

    const docId = getState().doc.docId;
    if( !Number.isInteger( docId ) ) return;
    
    const formData = new FormData();
    const fileName = file.name;
    formData.append( 'file', file );
    formData.append( 'fileName', fileName );
    formData.append( 'docId', docId );

    const config = { headers: { 'content-type': 'multipart/form-data' } };


    post_common(
        APP_URL + '/api/doc/upload'
       ,formData
       ,config
       ,dispatch
       ,caption.templateUploadError + ': '
       ,data => {
            if(data.status === 'OK') {
                successSnackbar( caption.templateSaved );
                
                //const extension = fileName.split('.').pop(); // not so good
                dispatch( setTemplateFormat( data.extension ) );

            } else if( data === "ERROR" ) {
                errorSnackbar( caption.templateUploadError );
            } else {
                // Ошибки шаблона DOCX
                const cause = data["cause"].toString();
                const location = data["location"];
                const tag = data["tag"];
                let result = "";
                if( cause.includes( "got '[a-zA-Z]+'" ) )
                    result = caption.tagConditionError;
                else if( cause.includes( "got '='" ) )
                    result = caption.tagConditionEqualError;
                else if( cause.includes( "end" ) && cause.includes( "if" ) )
                    result = caption.tagConditionEndIfError;
                else if( cause.includes( "if" ) )
                    result = caption.tagConditionError;
                else if( cause.includes( "}" ) )
                    if( tag && tag.includes("end" ) ) {
                        result = caption.tagConditionEndIfError;
                    } else {
                        result = caption.tagError;
                    }
                else
                    result = caption.tagUnknownError;

                if( location ) {
                    errorSnackbarLong( caption.badParagraph + location + "\n" + caption.cause + result );
                } else {
                    if( result === caption.tagConditionEndIfError && tag.length > 20 ) {
                        errorSnackbarLong( caption.error + "\n" + result );
                    } else {
                        errorSnackbarLong( caption.badConstruction + tag + " " + caption.cause + result );
                    }
                }
            }
        }
    );
};


let isRequestInProgress = false; // prevent multiple requests for createTicket() and startrekAttachDoc()
export function createTicket( dispatch, postData = null, next = null ) {
 
    const url = APP_URL + "/api/ticket/create";
    
    if( isRequestInProgress ) {
        return;
    }
    
    const props = dispatch( ( dispatch, getState ) => getState() );
    const isExternal = props.user ? props.user.isExternal : false;

    // передаём uuid пользователя внешних форм
    const extUserId = props.user.extUserId;
    if( postData ) {
        if( extUserId ) postData.append( 'extUserId', extUserId );
        postData.append( 'xxcdoc_lang', gLang );
    }

    const startrekUrl =  props.cdocProperties.startrekUrl;
    
    /* Раньше в data был номер тикета
    const lnk = data => isExternal ? caption.dataIsSent :
    <>
        Создан тикет&nbsp;
        {
            !startrekUrl ? data :
            <Link href={ startrekUrl + data } target="_blank" rel="noopener noreferrer" color="inherit">{ data }</Link>
        }
    </>;
    */
    // Теперь весь текст уведомления возвращается с backend,
    // потому что если тикет уже был создан по уникальной ссылке на шаблон, то будет добавлен комментарий в тот же тикет.
    // Последнюю часть - номер тикета, нужно обернуть в ссылку.
    const lnk = (text, ticket) => isExternal ? caption.dataIsSent :
        <>
            { caption[ text ] }&nbsp;
            {
                !startrekUrl ? ticket :
                <Link href={ startrekUrl + ticket } target="_blank" rel="noopener noreferrer" color="inherit">{ ticket }</Link>
            }
        </>;


    const config = {headers: {'content-type': 'multipart/form-data'}};

    isRequestInProgress = true;
    post_common(
        url
       ,postData
       ,config
       ,dispatch
       ,caption.ticketCreationError + ': '
       ,data => {
            isRequestInProgress = false;
            if( data === 'EXPIRED' ) {
                dispatch( setPropValue( DOC, 'expiredUrl', true ) );
                errorSnackbar( 'EXPIRED' );
            } else if( data === 'ERROR' ) {
                errorSnackbar( caption.ticketCreationError );
            } else if( data.message ) {
                errorSnackbarLong( caption.ticketCreationError + ': ' + data.message );
            } else {
                const text = data.replace( /\s\S+$/, '' ); // текст уведомления до последнего пробела
                const ticket = data.split(" ").pop(); // номер тикета идёт после последнего пробела
                
                // для внешнего пользователя нужно показать только одно уведомление "Спасибо, данные отправлены"
                if( !isExternal || !next ) successSnackbarLong( lnk( text, ticket ) );
                
                if( next ) next( ticket );
            }
        }
       ,() => { isRequestInProgress = false; } // better use axios finally()
    );
};


export function startrekAttachDoc( dispatch, data = null ) {
 
    const url = APP_URL + "/api/ticket/attach";
    
    if( isRequestInProgress ) {
        return;
    }

    const props = dispatch( ( dispatch, getState ) => getState() );
    const isExternal = props.user ? props.user.isExternal : false;

    // передаём uuid пользователя внешних форм
    const extUserId = props.user.extUserId;
    if( data ) {
        if( extUserId ) data.append( 'extUserId', extUserId );
        data.append( 'xxcdoc_lang', gLang );
    }

    const startrekQueue = data.get( "startrekQueueAttach" ).trim(); // очередь, компонента "Сохранить документ в существующий тикет"
    if( startrekQueue === undefined || startrekQueue === null || startrekQueue === '' ) {
        errorSnackbar( caption.noQueue );
        return;
    }
    const startrekTicket = data.get( "startrekAttachDoc" );
    if( startrekTicket === undefined || startrekTicket === null || startrekTicket === '' ) {
        errorSnackbar( caption.noTicket );
        return;
    }
    const attachQueue = startrekTicket.split(/-\s*/)[0].trim(); // номер тикета, компонента "Сохранить документ в существующий тикет"
    const startrekUrl =  props.cdocProperties.startrekUrl;

    if( startrekQueue.split( /;\s*/ ).findIndex( i => i === attachQueue ) < 0
     && startrekQueue.split( /\s+/  ).findIndex( i => i === attachQueue ) < 0 ) {
        errorSnackbar( caption.docQueueAllowed + ' ' + startrekQueue + '.' );
    } else {

        /* Раньше в data был номер тикета
        const lnk = data => isExternal ? caption.dataIsSent :
            <>{ caption.ATTACHED_TO_TICKET }&nbsp;
            {
                !startrekUrl ? data :
                <Link href={startrekUrl + data}
                    target="_blank" rel="noopener noreferrer" color="inherit"
                >{data}</Link>
            }
            </>;
        */
        // Теперь весь текст уведомления возвращается с backend,
        // потому что если тикет уже был создан по уникальной ссылке на шаблон, то будет добавлен комментарий в тот же тикет.
        // Последнюю часть - номер тикета, нужно обернуть в ссылку.
        const lnk = (text, ticket) => isExternal ? caption.dataIsSent :
            <>
                { caption[ text ] }&nbsp;
                {
                    !startrekUrl ? ticket :
                    <Link href={ startrekUrl + ticket } target="_blank" rel="noopener noreferrer" color="inherit">{ ticket }</Link>
                }
            </>;
        
        const config = { headers: { 'content-type': 'multipart/form-data' } };

        isRequestInProgress = true;
        post_common(
            url
           ,data
           ,config
           ,dispatch
           ,caption.attachError + ': ' // TODO: добавить номер тикета
           ,data => {
                isRequestInProgress = false;
                if( data === 'EXPIRED' ) {
                    dispatch( setPropValue( DOC, 'expiredUrl', true ) );
                    errorSnackbar( 'EXPIRED' );
                } else if( data === 'ERROR' ) {
                    errorSnackbar( caption.attachError );
                } else if( data.message ) {
                    errorSnackbarLong( caption.attachError + ': ' + data.message );
                } else {
                    const text = data.replace( /\s\S+$/, '' ); // текст уведомления до последнего пробела
                    const ticket = data.split(" ").pop(); // номер тикета идёт после последнего пробела
                    successSnackbarLong( lnk( text, ticket ) );
                }
            }
           ,() => { isRequestInProgress = false; } // better use axios finally()
        );
    }
};


export function loadStartrekFields( dispatch, queue ) {
    get_common(
        APP_URL + '/api/ticket/fields'
       ,dispatch
       ,caption.trackerFieldsError + ': '
       ,data => {
            dispatch( setPropValue( DOC, 'startrekFieldsList', data ) );
        }
        ,f=>f
        ,null
        ,{ params: { queue, xxcdoc_lang: gLang } }
    );
}


// процедура для прореживания запросов к Трекеру,
// при вводе названия очереди, выбирать типы задач автоматически, но не сразу, с задержкой
let isTimer = false; // защита от повторной установки таймера
export function fireIssueTypes( dispatch, queue ) {
    if( isTimer ) return;
    setTimeout( () => { isTimer = false; loadIssueTypes( dispatch, queue ); }, 500 );
    isTimer = true;
}


// типы задач очереди Трекера
export function loadIssueTypes( dispatch, queue ) {

    if( !dispatch || queue === '' || queue === null || queue === undefined ) return;
    
    dispatch( setKeyPropValue( DOC, queue, 'issueTypes', [] ) );
    
    get_common(
        APP_URL + '/api/ticket/types'
       ,dispatch
       ,caption.trackerTypesError + ': '
       ,data => {
            
            try {
             
                if( data.message ) {
                    errorSnackbarLong( caption.trackerTypesError + ': ' + data.message );
                } else {
                    // тип по умолчанию
                    //const { key, display } = data.defaultType;
                    //const defaultType = { key, display };
                    
                    // список типов задач
                    const issueTypes = data.issueTypes.map( i => ({ id: i.key, name: i.display }) );
                    dispatch( setKeyPropValue( DOC, queue, 'issueTypes', issueTypes ) );
                }

            } catch( ex ) {}

        }
        ,f=>f
        ,null
        ,{ params: { queue, xxcdoc_lang: gLang } }
    );

}




// список полей шаблона, у которых есть name и label,
// для интеграции со Startrek и для выбора поля, управляющего видимостью
export function loadTemplateItemsList( props ) {
    const docTemplate = props.doc.docTemplates[ props.doc.docId ];
    if( !Array.isArray( docTemplate ) ) return [];
    
    let list = docTemplate.reduce( (res, i, index) => {
     
        const labelEn = i[ "label_en" ];
        // если шаблон двуязычный, выбран английский язык интерфейса, и задано название поле на английском,
        // взять английское название, иначе - русское
        const label = isLangEn && gLang === "en" && labelEn !== undefined && labelEn !== null ? labelEn : i.label;
        
        if( i.name
         && label
         
         // TODO удалить, когда новый составной компонент Yandex заменит старый YandexOrgNameSelect
         // && i.name !== "systemName" // дублировать не нужно, но и отрезать нельзя, имя тега не уникально
         
         && i.type !== "StartrekIntegration" )
            res.push( { id: i.name, name: `${ label } (${ i.name })`, componentLabel: '', index, type: i.type } );
         
        // TODO удалить, когда новый составной компонент Yandex заменит старый YandexOrgNameSelect
        // if( i.type === "YandexOrgNameSelect" ) res.push( { id: "systemName", name:  "Системное наименование компании группы Яндекс (systemName)", componentLabel: '' } );
        
        // составные компоненты
        // поля группируются по заголовку компонента componentLabel
        if( i.fieldsList !== undefined ) {
            try {
                const suffix = i.suffix === undefined || i.suffix === null ? '' : i.suffix;
                const fieldsList = props.doc.docParts[ i.type ].admin.find( a => a.name === "fieldsList" ).list;
                fieldsList.forEach( f => res.push( {
                    id: `${ f.id }${ suffix }`
                   ,name: `${ f.name } (${ f.id }${ suffix })`
                   ,componentLabel: label
                   ,index
                   ,type: i.type
                } ) );
            } catch( e ) {}
        }
        
        return res;
    }, [] );
    
    // пустой componentLabel нужен для сортировки
    list.push( { id: "docCode", name: caption.docCode + " (docCode)", componentLabel: '' } ); // Код шаблона
    list.push( { id: "docName", name: caption.docName + " (docName)", componentLabel: '' } ); // Название шаблона
    
    const sortedList = list.sort( ( a, b ) => a.componentLabel.localeCompare( b.componentLabel ) ); // для InputWithSelect, сортировка для группировки
    
    props.dispatch( setPropValue( DOC, 'tmplItems', sortedList ) );
    return sortedList;
}


export const load = ( props, prevProps ) => {
    
    // загрузить шаблон из базы, если его нет
    const docId = props.doc.docId;
    if(
        docId !== prevProps.doc.docId
     && docId > 0
     && props.doc.docTemplates[ docId ] === undefined // ещё не загружен
     && props.doc.docNames.findIndex( i => i.id === docId ) > -1 // доступен
    ) {
        loadTemplateDB( props.dispatch, docId, props.doc.docAdminList, true );
    }
    
    /* лучше получить список на фронте из загруженного шаблона, перенёс в AdminForm.js, componentDidUpdate()
    // Загрузить список полей шаблона
    if(
        docId !== prevProps.doc.docId
     && docId > 0
     && props.doc.tmplItems.length === 0
     && props.doc.docTemplates[ docId ] === undefined // ещё не загружен
     && props.doc.docNames.findIndex( i => i.id === docId ) > -1 // доступен
    ) {
        loadTemplateItemsList( props.dispatch, docId );
    }*/
    
    // загрузить списки ролей из базы
    
    // Авторов групп назначает Суперпользователь
    const groupId = props.doc.groupId;
    if(
        groupId !== prevProps.doc.groupId
     && groupId > 0
     && props.role.groupAuthorList[ groupId ] === undefined
     && props.user.isSuper
    ) {
        loadRoleListDB( props.dispatch, "groupAuthorList", groupId, "GROUP", "ADMIN" );
    }
    
    // Админов и Пользователей шаблона назначает Админ шаблона или Суперпользователь
    if(
        docId !== prevProps.doc.docId
     && docId > 0
     && props.role.templateAdminList[ docId ] === undefined
     // для всех, чтобы показать список уполномоченных, если нет доступа к шаблону
     // && props.doc.docAdminList.findIndex( i => i.id === docId ) > -1
    ) {
        loadRoleListDB( props.dispatch, "templateAdminList", docId, "TEMPLATE", "ADMIN" );
    }
    
    if(
        docId !== prevProps.doc.docId
     && docId > 0
     && props.role.templateUserList[ docId ] === undefined
     && props.doc.docAdminList.findIndex( i => i.id === docId ) > -1
    ) {
        loadRoleListDB( props.dispatch, "templateUserList", docId, "TEMPLATE", "USER" );
    }

}
