import React from 'react';
import styles from "./Form.module.css";
import Button from './Button';
import MButton from '@material-ui/core/Button';
import { TextValidator } from 'react-material-ui-form-validator';
import {
    isObject
} from "../../redux/actions/utils";
import {
    caption as langCaption
} from "../../redux/actions/lang";


// Поле для компонентов SaveAndTicket и StartrekAttachDoc для отправки файла из браузера в тикет Трекера

const captions = {
    "ru": {
        send: "Отправить"
       ,file: "Файлы"
       ,chooseFile: "Выбрать файлы"
       ,helperText: <>Чтобы отправить документ:
        <ol style={{ margin: 0, paddingLeft: "1.3em", listStylePosition: "outside" }}>
            <li>Заполните форму, нажмите «Сохранить в файл», подпишите полученный документ,</li>
            <li>Нажмите «Выбрать файлы» и выберите файл с подписанным документом, можно выбрать несколько файлов,</li>
            <li>Нажмите «Отправить».</li>
        </ol></>
    }
   ,"en": {
        send: "Send"
       ,file: "Files"
       ,chooseFile: "Select files"
       ,helperText: <>To send document:
        <ol style={{ margin: 0, paddingLeft: "1.3em", listStylePosition: "outside" }}>
            <li>Fill in the form, click “Save to file”, sign the downloaded document,</li>
            <li>Click “Select files” and choose the file with the signed document, you can select multiple files,</li>
            <li>Click “Send”.</li>
        </ol></>
    }
};


const UploadFile = ( props ) => {
 
    const lang = props.lang ? props.lang : "ru";
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];
    
    const files = props.value;
    let fileNames = isObject( files ) ? Array.from( files ).map( file => file.name ).join( ", " ) : '';
    fileNames = fileNames === undefined || fileNames === null ? '' : fileNames;

    const readonly = true; // поле name серое, файл можно выбрать кнопкой chooseFile
    
    const showHelperText = props.showHelperText === false ? false : true;
    
    
    // создать тикет с подписанным документом
    return props.isUploadFile !== true ? '' :
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            { showHelperText && <div className='smallText padding'>{ props.instructions ?? langCaption.sendToYandex }</div> }
           
            <div className='padding' style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextValidator
                        className={ props.className }
                        label={ caption.file }
                        name={ props.name }
                        value={ fileNames }
                        InputLabelProps={{ shrink: !!fileNames }} // поднять label над полем, иначе может наехать на значение
                        onChange={ f=>f }
                        validators={ props.validators }
                        errorMessages={ props.errorMessages }
                        autoFocus={ props.autoFocus }
                        InputProps={{
                            readOnly: readonly
                           ,style: props.bold ? {fontWeight: 550} : {}
                           ,className: readonly ? 'Mui-disabled' : undefined
                        }}
                        // disabled={ readonly } // disabled поля не попадают в submit form
                        helperText={ props.helperText }
                        error={ props.error }
                    />
                    <MButton variant="contained" color="primary" component="label" style={{ margin: '10px 0px 0px 10px', minWidth: '140px', maxHeight: '36px' }}>
                        { caption.chooseFile }
                        <input
                          multiple
                          type="file"
                          // accept=".rtf"
                          onChange={ props.onMultiFileChange }
                          style={{ display: 'none' }}
                          name={ props.name }
                        />
                    </MButton>
                </div>
                
                { showHelperText &&
                <div className={ styles.xxHelperText } >
                    { caption.helperText }
                </div>
                }
            </div>
            
            <div className='table__row'>
            <Button
                className = "button100"
                onClick = { () => props.onUpload( fileNames ) }
                label = { caption.send }
            />
            </div>

        </div>;

}

export default UploadFile
