import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator';
import HiddenInput from "./HiddenInput";
import {
    GRAY
   ,HIDE
} from "../../redux/constants";


const Multiline = ( props ) => {
 
    if( !props.visible && props.visible !== undefined ) return;
    const value = props.value === undefined || props.value === null ? '' : props.value;

    // protect для внешней формы
    if( props.isExternal && props.protect === HIDE )
    return (
        <HiddenInput
            name = { props.name }
            value = { value }
        />
    );

    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    
    return (
        <div className='padding'>
            <TextValidator
                multiline
                className={ props.className }
                rows={ props.rows }
                rowsMax={ props.rowsMax }
                label={ props.label }
                name={ props.name }
                value={ value }
                InputLabelProps={{ shrink: !!value }}
                onChange={ props.onChange }
                InputProps={{
                    readOnly: readonly
                   ,style: props.bold ? {fontWeight: 550} : {}
                   ,className: readonly ? 'Mui-disabled' : undefined
                }}
                // disabled={ readonly } // disabled поля не попадают в submit form
                helperText={ props.helperText }
                validators={ props.validators }
                errorMessages={ props.errorMessages }
                inputProps={{
                    'data-type': props.dataType // для отображения Barcode в Preview
                }}
                placeholder={ props.placeholder }
            />
        </div>
    );
}

export default Multiline
