import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MCheckbox from '@material-ui/core/Checkbox';
import HiddenInput from "./HiddenInput";
import {
    transformMarkdown
} from "../../redux/actions/text";
import {
    GRAY
   ,HIDE
} from "../../redux/constants";


const Checkbox = ( props ) => {

    if( !props.visible && props.visible !== undefined ) return;

    const value = props.value === undefined || props.value === null ? false : props.value;
    const name = props.name;

    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    const hidden = props.isExternal && props.protect === HIDE;

    let hiddenInput = '';
    
    // protect для внешней формы
    if( readonly || hidden )
        hiddenInput = 
            <HiddenInput
                name = { name }
                value = { value }
            />;
        
    if( hidden )
        return hiddenInput;

    const label = transformMarkdown( props.label );
    
    return (
        <div className='padding'>
            { hiddenInput }
            <FormControlLabel
                control={
                    <MCheckbox
                        color = "primary"
                        name = { name }
                        checked = { value }
                        value = { value }
                        onChange = { props.onChange }
                    />
                }
                label={ label }
                disabled={ readonly } // disabled поля не попадают в submit form, поэтому дублируем значение в HiddenInput
            />
            { props.helperText && <FormHelperText>{ props.helperText }</FormHelperText> }
        </div>
    );
}

export default Checkbox
