import React from 'react';
import {
    setPropValue
} from './utils';
import { createDocPartTypes, createDocParts } from '../../components/DocParts/DocParts';
import { getStatusList } from './uploads';
import {
    DOC
   ,ROLE
} from "../constants";


// язык хранится:
// 1) в React state session.lang - используется в компонентах, а главное, изменение state запускает перерисовку,
// 2) и здесь, в глобальной переменной - для функций из actions/admin.js и подобной рассыпухи, здесь же хранятся переводы для этих функций

export let gLang = "ru";
export const langList = [ "en", "ru" ]; // список доступных языков
export const mainLang = "ru"; // основной язык
export let isLangEn = false; // хранить перевод на английский в шаблоне

export function setLangEn( value ) {
    isLangEn = value;
}

const captions = {
    "ru": {
       // Item.jsx
        addFieldAbove: "Добавить поле сверху"
       ,copyField: "Копировать поле"
       ,extVisibility: "Видимость поля на внешней форме"
       ,editList: "Редактировать список"
       ,editField: "Редактировать поле"
       
       // modals
       ,ok: "Ок"
       ,cancel: "Отмена"

        // AdminForm.js
       ,onGroupDelete: "Перед удалением группы нужно удалить или переместить все шаблоны из группы."
       ,deleteField: "Удалить поле"
       ,onFormError: "Исправьте ошибки в полях"
       
        // DocForm.js
       ,attachDocComment: "также прикладываю документ без пароля"
       ,attachAFile: "Приложите файл"

        // AdminForm.jsx
       ,uploadTemplate: "Загрузить шаблон"
       ,downloadTemplate: "Выгрузить шаблон"
       ,guide: "Инструкция"
       ,clearClipboard: "Очистить буфер обмена"
       ,reloadForm: "Обновить форму из базы"
       ,saveForm: "Сохранить форму"
       ,paste: "Вставить"
       ,addGroup: "Новая группа"
       ,editGroup: "Редактировать имя группы"
       ,deleteGroup: "Удалить группу"
       ,addForm: "Новый шаблон"
       ,linkToForm: "Ссылка на шаблон"
       ,copyForm: "Копировать шаблон"
       ,editForm: "Редактировать имя, код, группу шаблона"
       ,deleteForm: "Удалить шаблон"
       ,addField: "Добавить поле"
       ,accessToForm: "За доступом обратитесь к владельцу шаблона."
       ,extForm: "Ссылка на внешнюю форму"
       
        // ListEditor.jsx
       ,add: "Добавить значение"
       ,list: "Список"
       ,close: "Закрыть"
       ,deleteAll: "Удалить все"
       ,addAll: "Добавить все"
       ,field: "Поле"
       
        // BankListEditor.jsx
       ,bankDetailsFor: "Банковские реквизиты"
       ,bankNameWhiteList: "Допустимые значения"
       ,bankDefaultValue: "Значение по умолчанию"

        // ListItem.jsx
       ,bankDetails: "Банковские реквизиты"
       ,delete: "Удалить"
       ,move: "Подвинуть"
       
        // TemplateEditor.jsx
       ,docCopyName: "Название копии шаблона"
       
        // GroupEditor.jsx
       ,groupName: "Название группы"
       
        // DocFormContent.jsx
       ,expiredUrl: "Ссылка неактивна. Запросите новую ссылку."
       ,copyURL: "Ссылка на шаблон"
       ,redoFillOut: "Заполнить как в прошлый раз"
       ,clearForm: "Очистить форму"
       ,formAccess: "За доступом обратитесь к владельцам шаблона"
       
        // Preview.jsx
       ,showPreview: "Предпросмотр документа"
       ,hidePreview: "Закрыть предпросмотр документа"
       
        // admin.js
       ,group: "Группа"
       ,doc: "Шаблон"
       ,docCode: "Код шаблона"
       ,urlCopy: "Ссылка на шаблон скопирована в буфер обмена"
       ,formSaved: "Форма сохранена"
       ,formSaveError: "Ошибка сохранения формы"
       ,saveAdminPasswordError: "Ошибка сохранения пароля для редактирования документа"
       ,templateLoadError: "Ошибка загрузки шаблона"
       ,predefinedLoadError: "Ошибка загрузки данных шаблона"
       ,extdefinedLoadError: "Ошибка загрузки данных внешней формы"
       ,extprotectedLoadError: "Ошибка загрузки защищённых данных внешней формы"
       ,loadAdminPasswordError: "Ошибка загрузки пароля для редактирования документа"
       ,formReloaded: "Форма обновлена из базы"
       ,loadAppPropsError: "Ошибка при получении свойств приложения"
       ,loadSuperusersError: "Ошибка при получении списка суперпользователей"
       ,loadDepartmentsError: "Ошибка при получении списка подразделений пользователя"
       ,loadTepmlateListError: "Ошибка при получении списка шаблонов"
       ,loadGroupListError: "Ошибка при получении списка групп"
       ,templateUploadError: "Ошибка отправки шаблона"
       ,templateSaved: "Шаблон сохранён"
       ,dataIsSent: "Спасибо, данные отправлены"
       ,ticketCreationError: "Ошибка при создании тикета"
       ,docQueueAllowed: "Документ можно добавить только в тикет очереди"
       ,noTicket: "Номер тикета не указан"
       ,noQueue: "Очередь Трекера не указана"
       ,attachError: "Ошибка добавления документа в тикет"
       ,trackerFieldsError: "Ошибка при загрузке полей Трекера"
       ,trackerTypesError: "Ошибка при загрузке типов задач Трекера"
       ,docName: "Название шаблона"
       ,CREATED_TICKET: "Создан тикет"
       ,ATTACHED_TO_TICKET: "Документ добавлен в тикет"
       ,tagError: "Некорректная конструкция тега"
       ,tagErrorLine: "Номер строки"
       ,tagConditionError: "Некорректная конструкция условия if"
       ,tagConditionEqualError: "Некорректная конструкция условия if. Ожидается два знака =="
       ,tagConditionEndIfError: "Некорректная конструкция условия if. Ожидается {%endif%}"
       ,tagUnknownError: "Присутствует нераспознаваемое выражение"
       ,cause: "Причина: "
       ,error: "Ошибка: "
       ,badParagraph: "Ошибка в абзаце: "
       ,badConstruction: "Ошибка в конструкции: "
       
        // doc.js
       ,templateError: "Ошибка. Провалидируйте и загрузите файл шаблона."
       
        // groupEditor.js
       ,addGroupError: "Ошибка добавления группы"
       ,deleteGroupError: "Ошибка удаления группы"
       ,saveGroupError: "Ошибка сохранения группы"
       
        // hrPersons.js
       ,hrError: "Ошибка при получении данных HR о лицах"
       
        // role.js
       ,empListError: "Ошибка при получении списка сотрудников"
       ,depListError: "Ошибка при получении списка подразделений"
       ,addRoleError: "Ошибка добавления роли"
       ,delRoleError: "Ошибка удаления роли"
       ,roleListError: "Ошибка при получении списка ролей"
       ,staffAllListItem: { id: "ALL", name: "Все", type: "ALL", userName: "Все", userNameEn: "All" }
       
        // templateEditor.js
       ,addTemplateError: "Ошибка добавления шаблона"
       ,copyTemplateError: "Ошибка копирования шаблона"
       ,delTemplateError: "Ошибка удаления шаблона"
       ,saveTemplateError: "Ошибка сохранения шаблона"
       
        // uploads.js
       ,massTemplate: "Шаблон для масс-формирования документов"
       ,massLoadError: "Ошибка обработки файла"
       ,massResultError: "Ошибка при получении данных"
       ,uploadStatusError: "Ошибка при смене статуса загрузки"
       ,statusOk: "Успешно завершено"
       ,statusWarning: "Завершено с предупреждением"
       ,statusError: "Завершено с ошибкой"
       ,statusCancelled: "Отменено"
       ,statusRun: "В работе"
       ,statusUploaded: "Готово к запуску"
       ,statusNew: "Новая"
       ,statusAll: "Все"
       ,cancelUpload: "Отменить загрузку"

        // utils.js
       ,tokenError: "Ошибка при получении CSRF-токена"
       
        // vendorData.js
       ,vendorDataError: "Ошибка при получении данных о контрагенте"
       ,vendorListError: "Ошибка при получении списка контрагентов"
       
        // yandex.js
       ,yandexOrgError: "Ошибка при получении данных о компании" // "Яндекс" убрали для анонимности на внешних формах, CDOC-116
       ,yandexListError: "Ошибка при получении списка юрлиц"
       ,yandexBgError: "Ошибка при получении списка бизнес-групп"
       
        // GroupEditor.jsx
       ,newGroup: "Новая"
       
        // HistoryForm.js
       ,histTemplate: "Ошибка при получении информации о шаблоне: "
       ,histChange: "Ошибка при получении истории изменения шаблона: "
       ,comment: "Комментарий"
       
        // RoleForm.js
       ,roleMatrix: "Матрица ролей"
       ,roleTree: "Дерево ролей"
       ,downloadError: "Ошибка выгрузки файла"
       ,uploadError: "Ошибка загрузки файла"
       ,fileUploaded: "Файл загружен"
       
        // UploadsForm.js
       ,uploadsGetError: "Ошибка при получении данных о масс-загрузке: "
       ,uploadsNoComponent: 'Для данного шаблона не настроен компонент "Масс-формирование документов".'
       ,uploadsNotAllowed: " Загрузка невозможна."
       
        // UploadsForm.jsx
       ,fileName: "Имя файла"
       ,status: "Статус"
       
        // HistoryTable.jsx
       ,till: " по "
       ,now: "настоящее время"
       ,author: "Автор"
       ,form: "Форма"
       ,file: "Файл"
       ,templateFileName: "Имя файла шаблона"
       //,code: "Код"
       ,date: "Дата"
       ,version: "Версия"
       
        // HistoryFilter.jsx
       ,dateFrom: "Дата с"
       ,dateTo: "Дата по"
       ,update: "Обновить"
       ,numberOfLines: "Количество строк"
       
        // UploadsTable.jsx
       ,start: "Запустить обработку"
       ,uploadDate: "Дата загрузки"
       ,resultFile: <>Файл<br/>результата</>
       ,uploadFrom: <>Дата начала<br/>обработки</>
       ,uploadTo: <>Дата завершения<br/>обработки</>
       
        // ExcelActions.jsx
       ,downloadRoleMatrix: "Скачать матрицу ролей"
       ,uploadRoleMatrix: "Загрузить матрицу ролей"
       ,downloadRoleTree: "Скачать дерево ролей"
       ,uploadRoleTree: "Загрузить дерево ролей"
       
        // UploadsActions.jsx
       ,uploadData: "Загрузить файл с данными"
       
        // UploadsFilter.jsx
       ,filterLoadFrom: "Дата загрузки с"
       ,filterLoadTo: "Дата загрузки по"
       ,filterRunFrom: "Дата обработки с"
       ,filterRunTo: "Дата обработки по"
       
        // RoleForm.jsx
       ,roleGroup: "Авторы с правом на создание шаблонов в группе:"
       ,roleAdmin: "Админы шаблона:"
       ,roleUser: "Пользователи шаблона:"
       ,roleHr: "Доступ к функциональным компонентам"
       
        // ListEditor.js
       ,listAddAll: "Добавить все возможные значения?"
       ,listDelete: "Удалить значение?"
       ,listDeleteAll: "Удалить все значения?"
       
        // TemplateEditor.js
       ,newTemplate: "Новый"
       
        // User.js
       ,userError: "Ошибка при получении информации о пользователе: "
       
        // UploadFile.jsx
       ,sendToYandex: "Подписанный документ можно отправить обратно вашему контактному лицу:"
    }
    ,"en": {
       // Item.jsx
        addFieldAbove: "Add a field above"
       ,copyField: "Copy field"
       ,extVisibility: "Field visibility on external form"
       ,editList: "Edit list"
       ,editField: "Edit field"
     
       // modals
       ,ok: "Ok"
       ,cancel: "Cancel"
       
        // AdminForm.js
       ,onGroupDelete: "Before deleting a group, you must delete or move all form templates from the group."
       ,deleteField: "Delete field"
       ,onFormError: "Please correct errors in the fields"
       
        // DocForm.js
       ,attachDocComment: "I also attach the document without a password"
       ,attachAFile: "Attach a file"
        
        // AdminForm.jsx
       ,uploadTemplate: "Upload template"
       ,downloadTemplate: "Download template"
       ,guide: "Guide"
       ,clearClipboard: "Clear clipboard"
       ,reloadForm: "Reload form, undo changes"
       ,saveForm: "Save form"
       ,paste: "Paste"
       ,addGroup: "New group"
       ,editGroup: "Edit group name"
       ,deleteGroup: "Delete group"
       ,addForm: "New form"
       ,linkToForm: "Link to form"
       ,copyForm: "Copy form"
       ,editForm: "Edit form name, code, group"
       ,deleteForm: "Delete form"
       ,addField: "Add field"
       ,accessToForm: "For access, contact the form owner."
       ,extForm: "Link to external form"

        // ListEditor.jsx
       ,add: "Add value"
       ,list: "List"
       ,close: "Close"
       ,deleteAll: "Delete all"
       ,addAll: "Add all"
       ,field: "Field"
       
        // BankListEditor.jsx
       ,bankDetailsFor: "Bank details for"
       ,bankNameWhiteList: "Selected values"
       ,bankDefaultValue: "Default value"
       
        // ListItem.jsx
       ,bankDetails: "Bank details"
       ,delete: "Delete"
       ,move: "Move"
       
        // TemplateEditor.jsx
       ,docCopyName: "Form copy name"
       
        // GroupEditor.jsx
       ,groupName: "Group name"

        // DocFormContent.jsx
       ,expiredUrl: "URL is expired. Request a new link."
       ,copyURL: "Copy URL of this form"
       ,redoFillOut: "Redo last fill out"
       ,clearForm: "Clear the form"
       ,formAccess: "Please contact the form owners for access"
       
        // Preview.jsx
       ,showPreview: "Document preview"
       ,hidePreview: "Close document preview"
       
        // admin.js
       ,group: "Group"
       ,doc: "Form"
       ,docCode: "Form code"
       ,urlCopy: "URL is in the clipboard"
       ,formSaved: "Form is saved"
       ,formSaveError: "Form saving error"
       ,saveAdminPasswordError: "Error saving password for document editing"
       ,templateLoadError: "Form loading error"
       ,predefinedLoadError: "Form data loading error"
       ,extdefinedLoadError: "External form data loading error"
       ,extprotectedLoadError: "External form protected data loading error"
       ,loadAdminPasswordError: "Error loading password for document editing"
       ,formReloaded: "Form is reloaded"
       ,loadAppPropsError: "Error loading application properties"
       ,loadSuperusersError: "Error getting list of superusers"
       ,loadDepartmentsError: "Error getting list of person departments"
       ,loadTepmlateListError: "Error getting list of forms"
       ,loadGroupListError: "Error getting list of groups"
       ,templateUploadError: "Template upload error"
       ,templateSaved: "Template is saved"
       ,dataIsSent: "Thanks, the data has been sent"
       ,ticketCreationError: "Error when creating a ticket"
       ,docQueueAllowed: "Document can only be attached to a ticket in the queue"
       ,noTicket: "Ticket number not specified"
       ,noQueue: "Tracker queue not specified"
       ,attachError: "Error adding document to ticket"
       ,trackerFieldsError: "Error loading Tracker fields"
       ,trackerTypesError: "Error loading Tracker issue types"
       ,docName: "Form name"
       ,CREATED_TICKET: "Ticket created:"
       ,ATTACHED_TO_TICKET: "Document attached to ticket"
       ,tagError: "Incorrect tag construction"
       ,tagErrorLine: "Line number"
       ,tagConditionError: "Incorrect construction of the condition IF"
       ,tagConditionEqualError: "Incorrect construction of the condition IF. Two == signs are expected."
       ,tagConditionEndIfError: "Incorrect construction of the condition IF. Expected {%endif%}"
       ,tagUnknownError: "Unrecognizable expression"
       ,cause: "Cause: "
       ,error: "Error: "
       ,badParagraph: "Error in paragraph: "
       ,badConstruction: "Error in construction: "
       
        // doc.js
       ,templateError: "Error. Validate and upload the template file."
       
        // groupEditor.js
       ,addGroupError: "Error adding group"
       ,deleteGroupError: "Group deletion error"
       ,saveGroupError: "Error saving group"
       
        // hrPersons.js
       ,hrError: "Error when getting HR data"
       
        // role.js
       ,empListError: "Error getting list of employees"
       ,depListError: "Error getting list of departments"
       ,addRoleError: "Error adding role"
       ,delRoleError: "Role deletion error"
       ,roleListError: "Error getting list of roles"
       ,staffAllListItem: { id: "ALL", name: "All", type: "ALL", userName: "Все", userNameEn: "All" }
       
        // templateEditor.js
       ,addTemplateError: "Error adding form"
       ,copyTemplateError: "Form copy error"
       ,delTemplateError: "Form deletion error"
       ,saveTemplateError: "Form saving error"
       
        // uploads.js
       ,massTemplate: "Template for mass generation of documents"
       ,massLoadError: "File processing error"
       ,massResultError: "Error while getting data"
       ,uploadStatusError: "Error when changing upload status"
       ,statusOk: "Successfully completed"
       ,statusWarning: "Completed with warning"
       ,statusError: "Completed with error"
       ,statusCancelled: "Cancelled"
       ,statusRun: "In progress"
       ,statusUploaded: "Ready to run"
       ,statusNew: "New"
       ,statusAll: "All"
       ,cancelUpload: "Cancel processing"
       
        // utils.js
       ,tokenError: "Error while getting CSRF token"
       
        // vendorData.js
       ,vendorDataError: "Error when getting counterparty data"
       ,vendorListError: "Error when getting counterparty list"
       
        // yandex.js
       ,yandexOrgError: "Error when getting data about company"
       ,yandexListError: "Error when getting a list of legal entities"
       ,yandexBgError: "Error when getting a list of business groups"
       
        // GroupEditor.jsx
       ,newGroup: "New"
       
        // HistoryForm.js
       ,histTemplate: "Error getting template information: "
       ,histChange: "Error getting template change history: "
       ,comment: "Comment"
       
        // RoleForm.js
       ,roleMatrix: "Role matrix"
       ,roleTree: "Role tree"
       ,downloadError: "Download error"
       ,uploadError: "Upload error"
       ,fileUploaded: "File uploaded"
       
        // UploadsForm.js
       ,uploadsGetError: "Error retrieving mass generation data: "
       ,uploadsNoComponent: '"Mass generation of documents" component must be configured for template.' 
       ,uploadsNotAllowed: " Loading is not possible."
       
        // UploadsForm.jsx
       ,fileName: "File name"
       ,status: "Status"
       
        // HistoryTable.jsx
       ,till: " till "
       ,now: "now"
       ,author: "Author"
       ,form: "Form"
       ,file: "File"
       ,templateFileName: "Template file name"
       //,code: "Code"
       ,date: "Date"
       ,version: "Version"
       
        // HistoryFilter.jsx
       ,dateFrom: "Date from"
       ,dateTo: "Date to"
       ,update: "Update"
       ,numberOfLines: "Number of lines"
       
        // UploadsTable.jsx
       ,start: "Start processing"
       ,uploadDate: "Upload date"
       ,resultFile: <>Result<br/>file</>
       ,uploadFrom: <>Process<br/>start date</>
       ,uploadTo: <>Process<br/>end date</>
       
        // ExcelActions.jsx
       ,downloadRoleMatrix: "Download role matrix"
       ,uploadRoleMatrix: "Upload role matrix"
       ,downloadRoleTree: "Download role tree"
       ,uploadRoleTree: "Upload role tree"
       
        // UploadsActions.jsx
       ,uploadData: "Upload file with data"
       
        // UploadsFilter.jsx
       ,filterLoadFrom: "Upload date from"
       ,filterLoadTo: "Upload date to"
       ,filterRunFrom: "Process date from"
       ,filterRunTo: "Process date to"
       
        // RoleForm.jsx
       ,roleGroup: "Authors with permission to create forms in the group:"
       ,roleAdmin: "Form Admins:"
       ,roleUser: "Form Users:"
       ,roleHr: "Permissions to functional components"
       
        // ListEditor.js
       ,listAddAll: "Add all possible values?"
       ,listDelete: "Delete value?"
       ,listDeleteAll: "Delete all values?"
       
        // TemplateEditor.js
       ,newTemplate: "New"
       
        // User.js
       ,userError: "Error getting user information: "
       
        // UploadFile.jsx
       ,sendToYandex: "You can send signed document back to your contact person:"

    }
};

const yesNoCaptions = {
    "ru": {
        no: "Нет"
       ,yes: "Да"
    }
    ,"en": {
        no: "No"
       ,yes: "Yes"
    }
};

export let caption = captions.ru;
export let yesNoList = [];

export function setGlobalLang( value, dispatch ) {
    const lang = value ?? "ru";
    gLang = lang;
    caption = captions[ lang ] ?? captions[ "ru" ];
    
    let yesNoCaption = yesNoCaptions[ lang ] ?? yesNoCaptions[ "ru" ];
    yesNoList =
    [
        { id: false, name: yesNoCaption.no } // true, false - boolean для Checkbox, но для редактируемых полей нужно преобразовать в строку
       ,{ id: true, name:yesNoCaption.yes }
    ];

    const docParts = createDocParts( lang );
    const docPartTypes = createDocPartTypes( lang );
    dispatch( setPropValue( DOC, 'docParts', docParts ) );
    dispatch( setPropValue( DOC, 'docPartTypes', docPartTypes ) );
    
    // при переключении языка, сбросить список полей Трекера
    dispatch( setPropValue( DOC, 'startrekFieldsList', [] ) );
    
    getStatusList();
    
    // добавление пункта "Все" в списки на странице Роли
    // ответ Staff API для поиска лиц и подразделений
    dispatch( setPropValue( ROLE, 'staffGroupAuthorList', [ caption.staffAllListItem ] ) );
    dispatch( setPropValue( ROLE, 'staffTemplateAdminList', [] ) ); // Админы шаблона = Все - убрали, лишняя возможность
    dispatch( setPropValue( ROLE, 'staffTemplateUserList', [ caption.staffAllListItem ] ) );
    
    // сбросить типы задач для очереди Трекера,
    // они будут перезапрошены автоматически в Админке на выбранном языке
    dispatch( setPropValue( DOC, 'issueTypes', {} ) );
}


