import axios from 'axios'
import {
// properties
    APP_URL
// reducer names
   ,MODAL
// actions
   ,ADD_LIST_VALUES
   ,DELETE_KEY_EMPTY_PROPS
   //,SET_GLOBAL_LIST
   ,SET_LIST_BY_ID
   ,SET_KEY_PROP_VALUE
   ,SET_PROP_VALUE
   ,SET_PROPS
} from './../constants'
import { setIsLoading } from './spin'
import {
    caption
} from './lang';


export function isObject( value ) {
    return typeof value === 'object' && value !== null;
}


// Копирование текста в буфер обмена
// для Safari работает только через async
export const copyValue = async( value ) => {
    await navigator.clipboard.writeText( value );
}

/*
export function copyValue( value ) {
    
// "input", "textarea" - работает только на Win и в iOS Я.Браузер,
// iOS Safari работает только с localhost, а на сайте нет, буфер обмена защищён
    var element = document.createElement( "textarea" );
    element.value = value;
    // element.hidden = true; // нельзя, не скопируется
    element.setAttribute( 'readonly', '' );
    element.style.position = "absolute";
    element.style.top = "-9999px";
    element.style.left = "-9999px";
    document.body.appendChild( element );
    element.select();
    document.execCommand( "copy" );
    document.body.removeChild( element );

// writeText - тоже не работает в Safari
// Unhandled Promise Rejection: NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.
// попробовать асинхронно
//    navigator.clipboard.writeText( value );

}
*/

// 'dd.mm.yyyy' to Date
export const toDate = (dateStr) => {
  if( !dateStr ) return null; // для KeyboardDatePicker нужен null, а не ''
  const parts = dateStr.split(".");
  return dateStr ? new Date(parts[2], parts[1]-1, parts[0]) : '';
}

export const setMessage = ( msg ) => (
  {
    type: MODAL + SET_PROPS,
    payload: {
        value: { msg }
    }
  }
)

export const setProps = (prefix, value) => (
  {
    type: prefix + SET_PROPS,
    payload: {
        value
    }
  }
)

export const setPropValue = (prefix, name, value) => (
  {
    type: prefix + SET_PROP_VALUE,
    payload: {
        name 
       ,value
    }
  }
)

/*export const setGlobalList = (prefix, name, value) => (
  {
    type: prefix + SET_GLOBAL_LIST,
    payload: {
        name 
       ,value
    }
  }
)*/

export const setListById = (prefix, name, id, value) => (
  {
    type: prefix + SET_LIST_BY_ID,
    payload: {
        name 
       ,id
       ,value
    }
  }
)

export const addListValues = (prefix, name, value) => (
  {
    type: prefix + ADD_LIST_VALUES,
    payload: {
        name 
       ,value
    }
  }
)

export const setKeyPropValue = (prefix, key, name, value) => (
  {
    type: prefix + SET_KEY_PROP_VALUE,
    payload: {
        key
       ,name 
       ,value
    }
  }
)

export const deleteKeyEmptyProps = (prefix, key) => (
  {
    type: prefix + DELETE_KEY_EMPTY_PROPS,
    payload: {
        key
    }
  }
)

export const getDefaultConfig = () => {
    return {
        headers: {
            //"Accept": "application/json;charset=UTF-8",
            //"Content-Type": "application/json;charset=UTF-8",
            //"X-Webauth-Login": "sheba",
            "x-csrf-token": window.csrf_token
        }
    };
};


export const getCsrfToken = ( dispatch, next = f=>f ) => {

    get_common(
        APP_URL + '/api/csrf/token'
       ,dispatch
       ,caption.tokenError + ": "
       ,(data) => {
            window.csrf_token = data.token;
            next();
        }
       ,f=>f
       ,() => {
            // Обновляем страницу
            window.location.reload();
        }
       ,{}
       ,true
    );
};


export const get_common = ( url, dispatch, err_msg, action, next = f=>f, error_action = null, config = {}, skipRefreshSk = false ) => {
     const skipLoading = false; // это был параметр, чтобы скрывать индикатор ожидания ответа, сейчас не используется
     const axiosConfig = {
        ...config
       ,headers: {
            ...getDefaultConfig().headers
           ,...config.headers
        }
    };
    
    try {
        if( !skipLoading ) dispatch( setIsLoading( true ) );
        axios.get( url, axiosConfig )
        .then(
            ( { data } ) => {
                if( !skipLoading ) dispatch( setIsLoading( false ) )
                action( data )
                next()
            }
        )
        .catch(
            error => {
                if( !skipLoading ) dispatch( setIsLoading( false ) )
                
                if ( axios.isCancel( error ) ) {
                    return;
                }
                
                if( error.response && error.response.status === 403 ) {
                    if( !skipRefreshSk ) {
                        // Перезапрос после получения CSRF-токена
                        getCsrfToken( dispatch, () => get_common( url, dispatch, err_msg, action, next, error_action, config, true ) );
                    } else {
                        // Обновляем страницу
                        window.location.reload();
                    }
                } else {
                    error_action ?
                    error_action()
                    :
                    error && error.response && error.response.data && error.response.data.message ? (
                        dispatch( setMessage( err_msg + error.response.data.message ) )
                    ) : error ? (
                        dispatch( setMessage( err_msg + error ) )
                    ) : (
                        dispatch( setMessage( err_msg ) )
                    )
                }
            }
        )
    }
    catch ( error ) {
        if( !skipLoading ) dispatch( setIsLoading( false ) )
        dispatch( setMessage( err_msg + error.toString() ) )
    }
}


function getFilename( header ) {
    const match = header ? header.match( /filename\*=UTF-8''(.*?)$/ ) : undefined;
    
    const filename = Array.isArray( match ) ? decodeURIComponent( match[1] ) : undefined;
    return filename;
}


export const post_common = ( url, postData, config = {}, dispatch, err_msg, action, error_action = null, skipRefreshSk = false ) => {
 
     const axiosConfig = {
        ...config
       ,headers : {
            ...getDefaultConfig().headers
           ,...config.headers
        }
    };
    
    try {
        dispatch( setIsLoading( true ) )
        axios.post( url, postData, axiosConfig )
        .then(
            ( { data, headers } ) => {
                dispatch( setIsLoading( false ) );
                const filename = getFilename( headers["content-disposition"] );
                action( data, filename );
            }
        )
        .catch(
            error => {

                dispatch( setIsLoading( false ) )

                if ( axios.isCancel( error ) ) {
                    return;
                }

                if( error.response && error.response.status === 403 ) {
                    if( !skipRefreshSk ) {
                        // Перезапрос после получения CSRF-токена
                        getCsrfToken( dispatch, () => post_common( url, postData, config, dispatch, err_msg, action, error_action, true ) );
                    } else {
                        // Обновляем страницу
                        window.location.reload();
                    }
                } else {
                    // из ручки /doc возвращается ArrayBuffer - это файл Word или response с ошибкой
                    // { "timestamp":"2024-03-26T11:10:32.241+03:00", "status":500, "error":"Internal Server Error", "message":"Error in barcode data.", "path":"/api/doc" }
                    let response = null;
                    try {
                        response = error && error.response ? JSON.parse( new TextDecoder().decode( new Uint8Array( error.response.data ) ) ) : null;
                    } catch( e ) {}
                    let message = response ? response.message : null;

                    
                    if( error_action ) if( error_action() === "BREAK" ) return;

                    error && error.response && error.response.data && error.response.data.message ? (
                        //console.log(1) ||
                        dispatch( setMessage( err_msg + error.response.data.message ) )
                    ) : message && ( message !== 'No message available' ) ? (
                        //console.log(2) || console.log( response ) ||
                        dispatch( setMessage( message ) )
                    ) : error ? (
                        //console.log(3) || console.log( error.response ) ||
                        dispatch( setMessage( err_msg + error ) )
                    ) : (
                        //console.log(4) ||
                        dispatch( setMessage( err_msg ) )
                    )
                }
            }
        )
    } catch ( error ) {
        dispatch( setIsLoading( false ) )
        dispatch( setMessage( err_msg + error.toString() ) )
    }
}

// список уникальных значений
export const makeUnique = array => {
    return [ ...new Set( array ) ];
}
