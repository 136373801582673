import { connect } from 'react-redux';
import React, { Component } from 'react';
import { default as VDocForm } from '../components/DocForm/DocForm';
import { default as VDocFormContent } from '../components/DocForm/DocFormContent';
//import Tooltip from '../components/DocParts/Tooltip';
import Legend from '../components/DocParts/Legend';
import {
// properties
    APP_URL
   ,FORMAT
   ,IPROC
   ,ISNOPASS
   ,ATTACH_TO_TICKET
   ,UPLOAD_TO_TICKET
// reducer names
   ,DOC
} from '../redux/constants';
import {
    setPropValue
} from '../redux/actions/utils';
import {
    clearUuids
   ,fillEmptyCheckboxes
   ,getSubtemplateFiles
   ,getTables
   ,requestFile
   ,setDocStatePropValue
} from '../redux/actions/doc';
import {
    docChange
   ,groupChange
   ,createTicket
   ,load
   ,startrekAttachDoc
   ,setDocState
} from '../redux/actions/admin';
import {
    getLastDocState
   ,storeLastDocState
} from '../redux/actions/localStorage';
import {
    errorSnackbar
} from '../redux/actions/snackbar';
import {
    previewTemplate
} from '../redux/actions/preview';
import {
    gLang
   ,caption
} from '../redux/actions/lang';


let formAction = null;


function translateItem( item, name, lang ) {
    const trans = item[ name + '_' + lang ];
    
    // список [Ru] превращаем в список [ id: Ru, name: En ],
    // тогда defaultValue и visibilityParentValue можно задать только по-русски
    if( name === "list" && trans !== undefined ) {
        item.list = item.list.map( ( value, index ) => ({ id: value, name: trans[ index ] ?? "" }) );
        item.isLang = true; // для InputWithSelect
        return;
    }
    
    if( trans !== undefined ) item[ name ] = trans;
}

function modifyItem( item, lang ) {
    // lang - добавляем перевод для
    // label - Название поля на форме, list, startrekCommentTemplate и tooltip
    // идея в том, что если мы выбрали хранить переводы в шаблоне,
    // нужно добавить поля с суффиксом языка, label_en и т.п.
    if( lang !== 'ru' )
        [ "label", "list", "startrekCommentTemplate", "tooltip" ].forEach( name => translateItem( item, name, lang ) );
}

// build - отрисовать поля шаблона,
// группировать поля с признаком isRowGroup в одну строку
function buildDynForm( template, docParts, props, docState ) {

    const res = [];
    let idx = 0;
    
    const lang = gLang;
    // ИЛИ const lang = props.session.lang ?? "ru";

    template.forEach( ( item, templateIdx ) => {

        if( !docParts[ item.type ] ) return;
        
        const newItem = { ...item, lang };
        
        modifyItem( newItem, lang );
        
        const formField = docParts[ newItem.type ].build( // параметры build( props, state, extra, styles )
            props
           ,docState
           ,newItem
           ,undefined
        );
        
        // Подсказка для поля
        const tooltip = newItem.tooltip &&
            //<Tooltip title = { newItem.tooltip } />; // всплывающая подсказка
            <Legend value = { newItem.tooltip } />; // подсказка по клику
        
        // React требует keys.
        // Скрытые поля не оборачиваем в div.
        
        // TODO скрытые поля на обычной форме - undefined, их легко отсечь,
        // а на внешней - HiddenInput, чтобы значение попало в форму,
        // поэтому при группировке они создают пустоты в строке - тут пока нет решения,
        
        // TODO с tooltip похожая проблема,
        // условие formField ? не достаточно для компонент,
        // которые не пусты в скрытом состоянии - Ext (выводится иконка), Subtemplate (выводится HiddenInput)
        
        //formField, tooltip, template, templateIdx
        
        const formKeyField = formField ? <div key={ templateIdx } style={{ width: "100%" }} >{ tooltip }{ formField }</div> : '';
        
        const prevItem = templateIdx > 0 ? template[ templateIdx - 1 ] : null;
        if( prevItem && prevItem.isRowGroup && idx > 0 ) res[ idx - 1 ].push( formKeyField );
        else { res.push( [ formKeyField ] ); ++idx; }

    });

    return res;
}


// добавить в state формы параметры из url
function urlParams( dispatch, docId ) {
    const query = new URLSearchParams( window.location.search );
    query.forEach( ( value, key ) => {
        dispatch( setDocStatePropValue( key, value ) );
    });
}


class DocForm extends Component {

    componentDidUpdate( prevProps ) {

        // при загрузке первого шаблона на странице Документ
        // для обычного вида и iframe
        // для внутреннего и внешнего cdoc
        // добавить в state формы параметры из url
        // заполнить до predefined, extdefined и extprotected (admin.js)
        if( this.props.doc.docId !== "" && prevProps.doc.docId === "" ) {
            const dispatch = this.props.dispatch;
            urlParams( dispatch, this.props.docId );
        }
    
        // загрузить шаблон из базы, если его нет
        load( this.props, prevProps );
    }
    
    render() {
        const props = { ...this.props };
        
        // создать массив компонентов по шаблону формы
        const docNameObj = props.doc.docNames.find( i => i.id === props.doc.docId );
        const docTemplate = !props.doc.docTemplates || !props.doc.docId || !docNameObj ? null : props.doc.docTemplates[ props.doc.docId ];
        const docState = !props.doc.docStates || !props.doc.docId ? null : props.doc.docStates[ props.doc.docId ];
        const docParts = props.doc.docParts;

        // OLD VERSION: если есть компонента "Язык компонентов", получить язык
        //const langComponent = Array.isArray( docTemplate ) ? docTemplate.find( i => i.type === "Lang" ) : undefined;
        //const lang = langComponent ? langComponent.lang : undefined;
        
        //const lang = props.session.lang ?? "ru";

        const DynForm = !docParts || !Array.isArray( docTemplate ) || !docState ? null :
            buildDynForm( docTemplate, docParts, props, docState );

        props[ "DynForm" ] = DynForm;
        //props[ "lang" ] = lang;
        if( props.user && props.user.isIframe ) return <VDocFormContent {...props} />;
        return <VDocForm {...props} />;
    }
}


const mapStateToProps = ( { doc, role, spin, user, cdocProperties, session } ) => {
    return {
        doc
       ,role
       ,spin
       ,user
       ,cdocProperties
       ,session
    };
}


const mapDispatchToProps = dispatch => ({
    dispatch // передаю dispatch в props, для load
    //==================
    // Фильтрация ввода
    //==================
    // prevent firing button on Enter
   ,onFormKeyPress: ( e ) => {
        // allow Enter in textarea
        var txtArea = /textarea/i.test((e.target || e.srcElement).tagName);
        if( txtArea || (e.keyCode || e.which || e.charCode || 0) !== 13 ) {
            return true;
        } else {
            e.preventDefault();
        }
        return false;
    }
   ,onDocChange: ( e ) => {
        docChange( dispatch, e );
    }
   ,onGroupChange: ( e, oldGroupId ) => {
        groupChange( dispatch, e, oldGroupId );
    }
   ,onChange: ( e ) => {
    
        const target = e.target;
        const name = target.name;
        const type = target.type;
        const value = type === 'checkbox' ? target.checked :
                      type === 'file' ? target.files[0] :
                      target.value;
                
        // здесь так нельзя, файл будет сброшен и не отправится при отправке формы,
        // решил не сбрасывать, так даже удобнее, можно два раза отправить форму с тем же файлом
        //if( type === 'file' )
            //e.target.value = ''; // reset, to enable same file re-opening

        // Preview, скролл на редактируемое поле
        // вынес в setDocStatePropValue()
        //previewLastField( name );
        
        dispatch( setDocStatePropValue( name, value ) );
        
        // Preview Template - загрузка шаблона вместе с вложенными файлами Subtemplate,
        // и Barcode формируется на беке, хотя можно найти библиотеку для фронта
        try {
            if( type === 'file' || target.getAttribute( "data-type" ) === 'Barcode' ) {
                previewTemplate( dispatch );
            }
        } catch( ex ) { console.log( "preview: " + ex.message ); } // error "target.getAttribute is not a function" in "Money" component
    }
   ,onMultiFileChange: ( e ) => { // для загрузки нескольких файлов в компонентах SaveAndTicket и StartrekAttachDoc
    
        const target = e.target;
        const name = target.name;
        const type = target.type;
        const value = type === 'file' ? target.files : undefined;
                
        dispatch( setDocStatePropValue( name, value ) );
    }
   ,onNameValueChange: ( name, value ) => {
    
        // Preview, скролл на редактируемое поле
        // вынес в setDocStatePropValue()
        //previewLastField( name );
        
        dispatch( setDocStatePropValue( name, value ) );
    }
    
    //========================
    // Отправка данных с формы
    //========================
   ,onFormSubmit: ( e ) => {
        e.preventDefault();
        if( formAction ) {
            const formData = new FormData( e.target );
            // const inn = formData.get( "inn" );
            // if( !inn.match(/^[0-9]{9}$/) ) return; // проверки не нужны, если есть <input pattern>, полагаемся на onsubmit

            // пробежать по всем полям формы
            //for( let pair of formData.entries() ) {}
            
            // сохранить поля в кэше браузера,
            // очистить uuids, потому что они всегда должны быть новыми,
            // (и очистить поля формы с загружаемыми файлами) <- теперь файлы оставляю на форме, только в кэше чищу,
            // потому то из кэша файлы не возвращаются, только имя остаётся
            storeLastDocState( dispatch );
            
            // обновить все uuids в шаблоне, для этого достаточно удалить их из state,
            // компонент Uuid создаст новые значения
            dispatch( clearUuids() );
            
            // пустые checkboxes установить в false
            fillEmptyCheckboxes( dispatch, formData );
            
            // Subtemplate получаем не из file input формы, а из state,
            // потому что при переключении между формами state сохраняется,
            // а состояние file input - нет, форма пересоздаётся,
            // а также file input не позволяет программно подставить локальный файл из state
            getSubtemplateFiles( dispatch, formData );
            
            // Компонент Таблица
            // вместо значений полей Input
            // подставить JSON массив
            getTables( dispatch, formData );

            formAction( formData );
            formAction = null;
        }
    }
   ,onFormError: () => {
        //const message = errors.map( i => i.props.label ).join(', ');
        errorSnackbar( caption.onFormError );
    }
    // крестик "Очистить форму",
    // раньше можно было сбросить в пустое состояние {},
    // теперь - в predefined, чтобы остались предзаполненные поля на внешней форме
   ,onFormClear: () => {
        const predefined = dispatch( ( dispatch, getState ) => getState().doc.predefined );
        const state = predefined ? predefined : {};
        dispatch( setDocState( state ) );
    }
    // Заполнить как в прошлый раз
   ,onFormRedo: ( docId ) => {
        const lastDocState = getLastDocState( docId );
        if( lastDocState ) dispatch( setDocState( lastDocState ) );
    }
   ,onSaveDoc: ( format, isNopass = false ) => {
        formAction = ( data ) => {
            data.append( FORMAT, format );
            data.append( ISNOPASS, isNopass );
            requestFile( APP_URL + "/api/doc", "doc", format, dispatch, data );
        };
    }
   ,onCreateTicket: ( format, isNopass = false ) => {
        formAction = ( data ) => {
            data.append( FORMAT, format );
            data.append( ISNOPASS, isNopass );
            createTicket( dispatch, data );
        };
    }
   ,onDocAndTicket: ( format ) => {
        formAction = ( data ) => {
            data.append( FORMAT, format );
            createTicket( dispatch, data );
            requestFile( APP_URL + "/api/doc", "doc", format, dispatch, data );
        };
    }
    // тикет с PDF с паролем,
    // затем приложение с документом Word без пароля
   ,onTicketPdfRtfNopass: () => {
        formAction = ( data ) => {
            data.append( FORMAT, "pdf" );
            createTicket( dispatch, data,
                ( ticket ) => {
                    data.set( FORMAT, "rtf" );
                    data.set( ISNOPASS, true );
                    data.set( "startrekQueueAttach", data.get( "startrekQueue" ) ); // не используется в back, только для проверки на front
                    data.set( "startrekAttachDoc", ticket );
                    data.set( "startrekComment", caption.attachDocComment ); // "также прикладываю документ без пароля"
                    startrekAttachDoc( dispatch, data );
                }
            );
        };
    }
   ,onCreateTicketWithFile: ( fileNames ) => {
        formAction = ( data ) => {
            data.append( FORMAT, UPLOAD_TO_TICKET );
            if( fileNames === undefined || fileNames === null || fileNames === "" ) errorSnackbar( caption.attachAFile ); // "Приложите файл"
            else createTicket( dispatch, data );
        };
    }
   ,onStartrekAttachFile: ( fileNames ) => {
        formAction = ( data ) => {
            data.append( FORMAT, ATTACH_TO_TICKET );
            if( fileNames === undefined || fileNames === null || fileNames === "" ) errorSnackbar( caption.attachAFile ); // "Приложите файл"
            else startrekAttachDoc( dispatch, data );
        };
    }
   ,onCreateTicketIproc: ( format, isNopass = false ) => {
        formAction = ( data ) => {
            data.append( FORMAT, format );
            data.append( IPROC, "Y" );
            data.append( ISNOPASS, isNopass );
            createTicket( dispatch, data );
        };
    }
   ,onStartrekAttachDoc: ( format, isNopass = false ) => {
        formAction = ( data ) => {
            data.append( FORMAT, format );
            data.append( ISNOPASS, isNopass );
            startrekAttachDoc( dispatch, data );
        };
    }
   ,onClickShowPassword: ( showPassword ) => {
        dispatch( setPropValue( DOC, 'showPassword', !showPassword ) );
    }
   ,onClickPreview: ( showPreview ) => {
        dispatch( setPropValue( DOC, 'showPreview', !showPreview ) );
    }
});

export default connect( mapStateToProps, mapDispatchToProps )( DocForm );
